import React from "react"
import { graphql } from "gatsby"
import PagesLayout from "../../layout/pages"
import PageHeading from "../../utils/page-heading"
import BlogPostTags from "./tags"
import SinglePostOtherPosts from "./other-posts"
import AuthorInfo from "../preview/author-info"
import PostPreviewMeta from "../preview/meta"
import { FastCommentsCommentWidget } from "fastcomments-react"

export const blog = graphql`
  query singlePostQuery($id: String) {
    wpPost(id: { eq: $id }) {
      title
      uri
      content
      date(formatString: "DD MMMM YYYY")

      extraPostDetails{
        author
          authorImage {
            mediaItemUrl
          }
      }

      tags {
        nodes {
          name
        }
      }
    }

    allWpPost(
      filter: { id: { ne: $id } }
      sort: { fields: date, order: ASC }
      limit: 3
    ) {
      edges {
        node {
          title
          uri
          categories {
            nodes {
              name
            }
          }
          
          extraPostDetails{
            author
            authorImage {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`
const BlogPostSingle = ({ data }) => {
  const post = data.wpPost
  const otherPosts = data.allWpPost.edges

  const postAuthor = post.extraPostDetails?.author || "DearDesigner"
  const postAvatar = post.extraPostDetails?.authorImage?.mediaItemUrl

  return (
    <PagesLayout title={post.title} disablePadding>
      <div className='md:p-16 md:pb-24 pb-10'>
        <div className="my-12">
          <PostPreviewMeta date={post.date} content={post.content} />
        </div>

        <AuthorInfo name={postAuthor} image={postAvatar}/>
        <PageHeading text={post.title} />
        {/*<p*/}
        {/*  className="font-medium"*/}
        {/*  dangerouslySetInnerHTML={{ __html: post.excerpt }}*/}
        {/*></p>*/}

        <section
          className="single-blog-post border-dd-black-40 blog_post_container"
          dangerouslySetInnerHTML={{ __html: post.content }}
        ></section>

        {post.tags && <BlogPostTags tags={post.tags.nodes} />}

        <SinglePostOtherPosts posts={otherPosts} />

      </div>
      <div className='bg-white'>
        <FastCommentsCommentWidget
          tenantId='zOgIbfHFx'
          hideAvatars
          disableToolbar
          disableLiveCommenting
          translations={{
            ENTER_COMMENT_HERE: "Share your thoughts",
            SUBMIT_REPLY: "POST"
          }}
          customCSS={`
            @import "https://res.cloudinary.com/allofsimeon/raw/upload/v1639734996/DearDesigner/fonts_atof0g.css";

.fast-comments, textarea {
    font-family: 'DmSans';
}

.comment .commenter-name .label {
    display: none;
}

.username .activity-icon {
    display: none;
}

.comment > .inner > .comment-content .commenter-name > .username {
    font-size: 14px;
    font-weight: bold;
    color: #929292;
}

.comment > .inner > .comment-content .comment-text {
    font-size: 12px;
    line-height: 18px;
    color: #242424;
}

.comment > .inner > .comment-bottom .comment-vote-options {
    display: none;
}

.comment > .inner > .comment-bottom {
    margin-top: 16px;
}

.comment > .inner > .comment-bottom .comment-reply-start {
    margin: 0;
    padding: 0;
    font-size: 10px;
    line-height: 8px;
    letter-spacing: 0.185em;
    text-transform: uppercase;
    font-weight: normal;
    color: #929292;
}

.comment > .inner > .comment-bottom .comment-reply-start i {
    display: none;
}

.comment-reply.root {
    margin: 0;
    padding: 100px 20% 50px 10%;
    width: 100%;
    background: #EDEDED;
}

.comment-reply textarea {
    border: none;
    background: white;
    border-radius: 0;
}

.comment-reply .horizontal-border-wrapper {
    display: none;
}

.comment-reply.root .reply-button-wrapper,
.comment .reply-button-wrapper {
    position: relative;
    margin-bottom: 0px;
    border-radius: 0;
    float: unset;
    top: -24px;
    left: 24px;
}

.comment-reply.root .reply-button-wrapper::after,
.comment .fast-comments-reply::after {
    position: absolute;
    width: 31px;
    left: -3px;
    bottom: 1px;
    height: 3px;
    background: #DBDBDB;
    content: '';
}

.comment-reply.root button,
.comment .fast-comments-reply {
    border: none;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.17em;
    color: #494949;
    font-weight: normal;
    background: transparent;
    padding: 0;
    position: relative;
    z-index: 2;
}

.comment-reply.root button .bubble {
    display: none;
}

.comment-reply .comment-reply-top-bar {
    margin: 0 0 16px;
}

.select-dir-wrapper {
    width: 100%;
    background: #FAFAFA;
    padding: 16px 60px;
    box-sizing: border-box;
    border-color: #EDEDED;
    border-width: 2px;
}

.comments {
    padding: 60px;
    box-sizing: border-box;
    background: #FAFAFA;
    width: 100%;
}

.comment:first-of-type {
    margin-top: 0;
}

.comment > .inner > .comment-bottom > .comment-bottom-toolbar {
    min-height: 16px;
}

.replying-to {
    margin-bottom: 16px;
}

.comment > .inner > .comment-content .commenter-name > .username {
    font-weight: normal;
}

.comment-reply .comment-reply-top-bar .logged-in-info .avatar {
    margin-right: 16px;
}

@media (max-width: 640px) {
    .comment-reply.root {
        width: 100%;
        padding: 50px 10% 20px;
    }

    .select-dir-wrapper,
    .comments {
        margin-left: 0;
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
    }
}
          `}
        />
      </div>
    </PagesLayout>
  )
}

export default BlogPostSingle
