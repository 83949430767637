import React from "react"
import BlogPostPreview from "../../preview"
import ctl from "@netlify/classnames-template-literals"

const SinglePostOtherPosts = ({ posts }) => {
  const other_posts_container_style = ctl(
    `grid md:grid-cols-3 grid-cols-1 gap-16 mt-8`
  )

  const other_posts_heading_style = ctl(
    `text-[16px] uppercase tracking-widest font-bold mt-14`
  )
  return (
    <section>
      <h3 className={other_posts_heading_style}>Other Posts</h3>
      <hr />

      <div className={other_posts_container_style}>
        {posts.map((post, index) => (
          <BlogPostPreview outline post={post.node} key={index} />
        ))}
      </div>
    </section>
  )
}

export default SinglePostOtherPosts
