import React from "react"
import ctl from "@netlify/classnames-template-literals"

const BlogPostTagItem = ({ name }) => {
  const blog_post_tag_item_style = ctl(
    ` inline-block md:p-[10px] p-[8px] uppercase md:text-[12px] text-10px text-dd-black-60 bg-dd-black-20 rounded tracking-[1.5px] mr-[10px] last:mr-[10px] `
  )

  return <span className={blog_post_tag_item_style}>{name}</span>
}

export default BlogPostTagItem
