import React from "react"
import BlogPostTagItem from "./item"

import ctl from "@netlify/classnames-template-literals"

const BlogPostTags = ({ tags }) => {
  const tags_style = ctl(`mt-16 flex flex-wrap gap-y-4 lg:flex-nowrap`)
  return (
    <div className={tags_style}>
      {tags.map((tag, index) => {
        return <BlogPostTagItem name={tag.name} key={index} />
      })}
    </div>
  )
}

export default BlogPostTags
